import React from "react";
import ReactGA from "react-ga4";

import {
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";

const playerStyle = {
  width: '100%',
  height: '600px',
  maxWeight: '980px',
  borderRadius: '3px'
};

const header1Style = {
  textAlign: 'center',
  fontSize:'42px',
  marginBottom: '24px'
}

const header3Style = {
  textAlign: 'center',
  color: '#FD6E8B',
  marginBottom: '12px',
  fontSize: '21px'
}

const header5Style = {
  textAlign: 'center',
  lineHeight: '2',
  fontSize: '14px'
}

const beatStoreContainerStyle = {
  paddingTop: '0px',
  paddingBottom: '0px',
}

const iconStyle = {
  marginRight: '8px',
}

const buttonStyle = {
  marginTop: '32px',
  marginBottom: '32px',
}


class BeatStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: "" };
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://pagebuilder-cdn.soundee.com/analytics.js";
    script.async = true;

    document.body.appendChild(script);

    let url = "https://190.soundee.com";

    this.setState({url});
  }

  render() {
    return (
      <Container>
        <h1 style={header1Style}>Instant Beat Store</h1>
        <h3 style={header3Style}>☀️ Summer SALE IS LIVE NOW! 🍦 </h3>
        <h5 style={header5Style}>
          4x Basic License – <strike>$200</strike> $60
          <br/>4x Premium License – <strike>$400</strike> $105
          <br/>4x Unlimited License – <strike>$800</strike> $150
          <br/>
          <br/>Add 4 beats of the same license to your cart, use coupon code "summer40" at checkout for over 70% OFF Discount!
        </h5>
        <div style={beatStoreContainerStyle} className="section section-beat-store">
          <iframe id="soundee_player" frameBorder="0" src={this.state.url} allow="autoplay" style={playerStyle}></iframe>
        </div>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <Button
              className="btn"
              color="danger"
              href="/licensing"
              role="button"
              size="lg"
              style={buttonStyle}
              onClick={()=>ReactGA.event({
                category: 'Info',
                action: 'Click Homepage View Licensing Terms Button'
              })}
            >
              <i className="fa fa-cogs" style={iconStyle} />
              View Licensing Terms
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BeatStore;