import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import{ Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "assets/css/nucleo-icons.css";
import ReactGA from "react-ga4";

const iconStyle = {
  color: "white",
  background: "#28a745",
  padding: "4px",
  borderRadius: "50%",
}

const bannerStyle = {
  marginTop: '128px',
  marginBottom: '96px',
  maxWidth: '80%'
}

const containerStyle = {
  textAlign: 'center'
}



class PageHeader extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          modal: false,
      };
      this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
      this.setState({
          modal: !this.state.modal
      });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
          modal: this.props.modal
      });
    }, 1000);
  }

  render() {
    return (
      <div style={containerStyle}>
      <img src={require("assets/img/summer.jpg")} style={bannerStyle} />
      {/*}
      <div className="page-header header-filter">
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="squares square7" />
        <Container>
          <div className="content-center brand">
            <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Thank You For Signing Up!&nbsp;
                  <i style={iconStyle} className="tim-icons icon-check-2"/>
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={this.toggleModal}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </div>
              <ModalBody>
                  <p>An email will arrive in your inbox shortly.</p>
              </ModalBody>
              <ModalFooter>
                  <div/>
                  <Button color="secondary" onClick={this.toggleModal}>
                      Close
                  </Button>
              </ModalFooter>
            </Modal>
            <h1 className="h1-seo">Indie Rock Beats</h1>
            <h3 className="d-none d-sm-block">
              Get 30+ Handcrafted Beats<br />Straight In Your Inbox.
            </h3>
            <a href="https://thepacolee42310.activehosted.com/f/1">
              <Button onClick={()=>ReactGA.event({
                category: 'Promotion',
                action: 'Click Homepage Free Beats Button'
              })} color="primary"><b>Subscribe</b></Button>
            </a>
          </div>
        </Container>
        */}
      </div>
    );
  }
}

export default PageHeader;
